
import { computed, defineComponent, onBeforeMount, reactive, watch } from 'vue';

import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';

import UserModule from '@/store/user';
import PartnerEntityModule, { PartnerType } from '@/store/partners/entity';

import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { PartnerTextFields } from '@/interfaces/partner.interface';
import { clearPhone, formatPhoneWithBrackets } from '@/lib/Utils';
import { getRegionsGeoAll } from '@/api/region';
import { getSuggestionBanksByBic } from '@/api/daData';
import {
  GuiAutocomplete,
  GuiButton,
  GuiCheckbox,
  GuiCheckboxGroup,
  GuiDropdown,
  GuiRadioGroup,
  GuiTextInput,
} from '@library/gigant_ui';
import { DropdownItem } from '@library/gigant_ui/dist/types/interfaces/dropdownItem';
import { RadioGroupItem } from '@library/gigant_ui/dist/types/interfaces/radioGroupItem';
import useVuelidate from '@vuelidate/core';
import validator from '@/lib/validator';
import { Autocomplete } from '@/lib/formFactory/autocomplete.interface';
import Loading from '@/components/Loading.vue';
import { CheckboxGroupItem } from '@library/gigant_ui/dist/types/interfaces/checkboxGroupItem';
import { helpers } from '@vuelidate/validators';
import { CatchFormResponse } from '@/interfaces/shared';
import { DaDataSearchItem, BankSearchItem, BicSearchItem } from '@/interfaces/bankResponse.interface';

interface PartnerForm {
  [key: string]: string | boolean | Autocomplete | CheckboxGroupItem[];
  type: string;
  legalName: string;
  legalAddress: string;
  actualAddress: string;
  region: string;
  phone: string;
  email: string;
  requiresNotification: boolean;
  isSupplier: boolean;
  isAgent: boolean;
  directorFio: string;
  inn: string;
  kpp: string;
  ogrn: string;
  bank: string;
  bankAccount: string;
  bik: string;
  isTest: boolean;
  onDemandPaymentEnabled: boolean;
  accountTypes: CheckboxGroupItem[];
}

interface PartnerState {
  [key: string]: boolean;
  isLoading: boolean;
}

interface PartnerStateParams {
  partnerTypeItems: RadioGroupItem[];
  regionItems: DropdownItem[];
  bikItems: BicSearchItem[];
  bikLoading: boolean;
  bankItems: BankSearchItem[];
  bankLoading: boolean;
}

export default defineComponent({
  name: 'PartnerForm',
  emits: ['returnBefore'],
  components: {
    GuiAutocomplete,
    GuiCheckboxGroup,
    GuiButton,
    GuiCheckbox,
    GuiDropdown,
    GuiTextInput,
    GuiRadioGroup,
    AppFieldset,
    AppRow,
    AppCol,
    Loading,
  },
  props: {
    partnerId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const state: PartnerState = reactive({
      isLoading: true,
    });
    const partnerForm: PartnerForm = reactive({
      type: '',
      legalName: '',
      legalAddress: '',
      actualAddress: '',
      region: '',
      phone: '',
      email: '',
      requiresNotification: false,
      isSupplier: false,
      isAgent: false,
      directorFio: '',
      inn: '',
      kpp: '',
      ogrn: '',
      bank: '',
      bankAccount: '',
      bik: '',
      isTest: false,
      onDemandPaymentEnabled: false,
      accountTypes: [
        {
          label: 'Реализатор',
          value: false,
          disabled: false,
        },
        {
          label: 'Агент',
          value: false,
          disabled: false,
        },
      ],
    });

    const canEditPartner = computed(() => {
      return UserModule.userHasPermission('CAN_EDIT_PARTNER');
    });

    const isNotSelfEmployed = computed(() =>
      [PartnerType.Legal, PartnerType.Individual].includes(partnerForm.type as PartnerType)
    );

    const vRulesForFio = {
      regExp: validator({
        name: 'regexp',
        message: 'Неверный формат',
        attributes: /^[А-Яа-яЁёЪъ\- ]+$/,
      }),
      regExp2: validator({
        name: 'unRegexp',
        message: 'Неверный формат',
        attributes: /( -)/g,
      }),
      regExp3: validator({
        name: 'unRegexp',
        message: 'Неверный формат',
        attributes: /(- )/g,
      }),
    };
    const validationRules = computed(() => {
      const localRules: Record<string, unknown> = {
        type: {
          required: validator({ name: 'required' }),
        },
        legalName: {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Доступны буквы кириллицы, цифры и символы',
            attributes: /^[а-яА-ЯёЁ^<>().,;:@"!?+\-_#№%$&*=|/~±`'§\d\s\\[\]]*$/,
          }),
        },
        legalAddress: {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Доступны буквы кириллицы, цифры и символы',
            attributes: /^[а-яА-ЯёЁ^<>().,;:@"!?+\-_#№%$&*=|/~±`'§\d\s\\[\]]*$/,
          }),
        },
        actualAddress: {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Доступны буквы кириллицы, цифры и символы',
            attributes: /^[а-яА-ЯёЁ^<>().,;:@"!?+\-_#№%$&*=|/~±`'§\d\s\\[\]]*$/,
          }),
        },
        region: {
          required: validator({ name: 'required' }),
        },
        phone: {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный номер телефона',
            attributes: /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/,
          }),
        },
        email: {
          required: validator({ name: 'required' }),
          email: validator({ name: 'email' }),
        },
        bank: {
          required: validator({ name: 'required' }),
        },
        bankAccount: {
          required: validator({ name: 'required' }),
          regExp4: validator({
            name: 'regexp',
            message: 'Некорректный расчетный счёт. Должен начинаться с цифры 4.',
            attributes: /^4/,
          }),
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный расчетный счёт (20 цифр)',
            attributes: /\d{20}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный расчетный счёт (20 цифр)',
            attributes: 20,
          }),
        },
        bik: {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный БИК (9 цифр)',
            attributes: /\d{9}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный БИК (9 цифр)',
            attributes: 9,
          }),
        },
        accountTypes: {
          requiredCheckbox: helpers.withMessage(
            'Нужно выбрать один или несколько вариантов',
            (value: CheckboxGroupItem[]) => value[0].value || value[1].value
          ),
        },
      };

      if (partnerForm.type === PartnerType.Legal) {
        localRules.directorFio = {
          required: validator({ name: 'required' }),
          ...vRulesForFio,
        };

        localRules.inn = {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ИНН (10 цифр)',
            attributes: /\d{10}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ИНН (10 цифр)',
            attributes: 10,
          }),
        };

        localRules.kpp = {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат КПП (9 цифр)',
            attributes: /\d{9}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат КПП (9 цифр)',
            attributes: 9,
          }),
        };

        localRules.ogrn = {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ОГРН (13 цифр)',
            attributes: /\d{13}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ОГРН (13 цифр)',
            attributes: 13,
          }),
        };
      } else {
        localRules.inn = {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ИНН (12 цифр)',
            attributes: /\d{12}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ИНН (12 цифр)',
            attributes: 12,
          }),
        };

        if (partnerForm.type === PartnerType.Individual) {
          localRules.directorFio = {
            ...vRulesForFio,
          };

          localRules.ogrn = {
            required: validator({ name: 'required' }),
            regExp: validator({
              name: 'regexp',
              message: 'Неверный формат ОГРНИП (15 цифр)',
              attributes: /\d{15}/,
            }),
            maxLength: validator({
              name: 'maxLength',
              message: 'Неверный формат ОГРНИП (15 цифр)',
              attributes: 15,
            }),
          };
        }
      }

      return localRules;
    });
    const v$ = useVuelidate(validationRules, partnerForm);

    const stateParams: PartnerStateParams = reactive({
      partnerTypeItems: [
        {
          label: 'Индивидуальный предприниматель',
          nativeValue: PartnerType.Individual,
          disabled: false,
        },
        {
          label: 'Юридическое лицо',
          nativeValue: PartnerType.Legal,
          disabled: false,
        },
        {
          label: 'Самозанятый',
          nativeValue: PartnerType.SelfEmployed,
          disabled: false,
        },
      ],
      regionItems: [],
      bikItems: [],
      bikLoading: false,
      bankItems: [],
      bankLoading: false,
    });

    const initRegionsSelect = async (): Promise<void> => {
      try {
        const regionsGeoAll = await getRegionsGeoAll();

        stateParams.regionItems = regionsGeoAll.map((region) => ({
          id: region.id.toString(),
          label: region.name,
          icon: '',
          value: region.id.toString(),
          disabled: false,
        }));
      } catch (error) {
        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
        stateParams.regionItems = [];
      }
    };

    //
    // методы для поиска БИК
    //
    const updateSelectedBik = (selected: string): void => {
      stateParams.bikItems.map((item: BicSearchItem) => {
        if (item.value === selected) {
          partnerForm.bank = item.bank;
        }
      });
    };

    const prepareBikList = (banks: DaDataSearchItem[]): BicSearchItem[] => {
      return banks.map((bank: DaDataSearchItem) => {
        return {
          id: bank.bic,
          link: bank.name,
          value: bank.bic,
          ks: bank.correspondentAccount,
          bank: bank.name,
        };
      });
    };

    const searchBic = async (value: string): Promise<void> => {
      try {
        if (value && value.length === 0) {
          stateParams.bikItems = [];

          return;
        }

        stateParams.bikLoading = true;
        const result = await getSuggestionBanksByBic(value);
        stateParams.bikItems = prepareBikList(result);
        stateParams.bikLoading = false;
      } catch (error) {
        stateParams.bikItems = [];
      }
    };
    //
    // методы для поиска Банка
    //
    const updateSelectedBank = (selected: string): void => {
      stateParams.bankItems.map((item: BankSearchItem) => {
        if (item.value === selected) {
          partnerForm.bik = item.bic;
        }
      });
    };

    const prepareBankList = (banks: DaDataSearchItem[]): BankSearchItem[] => {
      return banks.map((bank: DaDataSearchItem) => {
        return {
          id: bank.name,
          link: bank.bic,
          value: bank.name,
          ks: bank.correspondentAccount,
          bic: bank.bic,
        };
      });
    };

    const searchBank = async (value: string): Promise<void> => {
      try {
        if (value && value.length === 0) {
          stateParams.bikItems = [];

          return;
        }

        stateParams.bankLoading = true;
        const result = await getSuggestionBanksByBic(value);
        stateParams.bankItems = prepareBankList(result);
        stateParams.bankLoading = false;
      } catch (error) {
        stateParams.bankItems = [];
      }
    };
    //
    //
    //
    const updateAccountType = (value: CheckboxGroupItem[]) => {
      partnerForm.isSupplier = value[0].value;
      partnerForm.isAgent = value[1].value;

      partnerForm.accountTypes[0].value = partnerForm.isSupplier;
      partnerForm.accountTypes[1].value = partnerForm.isAgent;

      //
      // проверяем, надо ли делать какие-то поля недоступными (доступными) для выбора
      //
      checkAccountType();
    };

    const edit = async (): Promise<void> => {
      try {
        await PartnerEntityModule.saveUpdate({
          id: props.partnerId as string,
          textFields: textFields(),
        });
      } catch (error) {
        console.error(error);
      }
    };

    const add = async (): Promise<void> => {
      try {
        const result = await PartnerEntityModule.addNew({
          textFields: textFields(),
        });

        if (result && !result.message) {
          returnBefore();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const save = async (): Promise<void> => {
      await v$.value.$validate();
      if (v$.value.$error) {
        return;
      }

      if (props.partnerId.length) {
        await edit();
      } else {
        await add();
      }
    };

    const returnBefore = (): void => {
      emit('returnBefore');
    };

    onBeforeMount(async () => {
      await initRegionsSelect();

      if (props.partnerId && props.partnerId.length) {
        let unwatchLoading!: ReturnType<typeof watch>;

        await new Promise<void>((resolve) => {
          unwatchLoading = watch(
            () => PartnerEntityModule.isLoading,
            (isLoading) => {
              if (!isLoading) {
                resolve();
              }
            },
            { immediate: true }
          );
        });

        if (unwatchLoading) {
          unwatchLoading();
        }

        const model = PartnerEntityModule.model;

        partnerForm.type = model.type;
        partnerForm.legalName = model.legalName;
        partnerForm.legalAddress = model.legalAddress;
        partnerForm.actualAddress = model.actualAddress;
        partnerForm.region = model.region;
        partnerForm.isSupplier = model.isSupplier;
        partnerForm.isAgent = model.isAgent;
        partnerForm.region = model.region;
        partnerForm.phone = formatPhoneWithBrackets(model.phone);
        partnerForm.email = model.email;
        partnerForm.requiresNotification = model.requiresNotification;
        partnerForm.directorFio = model.directorFio;
        partnerForm.inn = model.inn;
        partnerForm.kpp = model.kpp;
        partnerForm.ogrn = model.ogrn;
        partnerForm.bank = model.bank;
        partnerForm.bankAccount = model.bankAccount;
        partnerForm.bik = model.bik;
        partnerForm.isTest = model.isTest;
        partnerForm.onDemandPaymentEnabled = model.onDemandPaymentEnabled;

        partnerForm.accountTypes[0].value = partnerForm.isSupplier;
        partnerForm.accountTypes[1].value = partnerForm.isAgent;

        //
        // проверяем, надо ли делать какие-то поля недоступными (доступными) для выбора
        //
        checkAccountType();

        state.isLoading = false;
      } else {
        state.isLoading = false;
      }
    });

    const textFields = (): PartnerTextFields => {
      const fields: PartnerTextFields = {
        type: partnerForm.type,
        legalName: partnerForm.legalName,
        legalAddress: partnerForm.legalAddress,
        actualAddress: partnerForm.actualAddress,
        phone: clearPhone(partnerForm.phone).slice(1),
        email: partnerForm.email,
        requiresNotification: partnerForm.requiresNotification ? '1' : '0',
        isSupplier: partnerForm.isSupplier ? '1' : '0',
        isAgent: partnerForm.isAgent ? '1' : '0',
        inn: partnerForm.inn,
        bank: partnerForm.bank,
        bankAccount: partnerForm.bankAccount,
        bik: partnerForm.bik,
        region: partnerForm.region as string,
        isTest: partnerForm.isTest ? '1' : '0',
        onDemandPaymentEnabled: partnerForm.onDemandPaymentEnabled ? '1' : '0',
      };

      if (partnerForm.type === PartnerType.Legal) {
        fields.kpp = partnerForm.kpp;
      }

      if (partnerForm.type !== PartnerType.SelfEmployed) {
        fields.ogrn = partnerForm.ogrn;
        fields.directorFio = partnerForm.directorFio;
      }

      return fields;
    };

    /**
     * метод проверяет условие доступности типа аккаунта "Реализатор"
     */
    const checkAccountType = () => {
      if (partnerForm.type === PartnerType.SelfEmployed) {
        partnerForm.accountTypes[0].value = false;
        partnerForm.accountTypes[0].disabled = true;
      } else {
        partnerForm.accountTypes[0].disabled = false;
      }

      if (partnerForm.isSupplier) {
        stateParams.partnerTypeItems[2].disabled = true;
        if (partnerForm.type === PartnerType.SelfEmployed) {
          partnerForm.type = '';
        }
      } else {
        stateParams.partnerTypeItems[2].disabled = false;
      }
    };

    return {
      partnerForm,
      state,
      stateParams,
      isNotSelfEmployed,
      canEditPartner,

      text: strings,

      updateSelectedBik,
      prepareBikList,
      searchBic,
      updateSelectedBank,
      prepareBankList,
      searchBank,
      updateAccountType,
      edit,
      add,
      save,
      checkAccountType,
      returnBefore,

      v$,

      PartnerType,
    };
  },
});
